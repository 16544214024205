<!--
 * @Descripttion:
 * @version:
 * @Author: 李宗哲
 * @Date: 2020-03-09 17:12:51
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2021-01-06 22:26:50
 -->
 <template>
  <div>
    <div class="content">
      <div class="showLeft">
        <div
          class="showImg"
          :style="`background-image:url(${ossurl}${prodcp.bannerhttp})`"
        >
          <div class="hbsc" @click="use()">
            <span class="ztyangshi">海报生成</span>
            <img src="@/assets/nh/nhwxcpxq-01.png" alt="" class="seemainpic" />
          </div>
        </div>
        <div class="showTitle">
          <p class="cpname">{{ prodcp.prodname }}</p>
          <p class="lookcp" @click="jump()">
            查看产品
            <md-icon name="arrow-right" size="lg" style="font-size: 0.3rem">
            </md-icon>
          </p>
        </div>
      </div>
      <div style="height: 0.3rem; background-color: #f6f6f6"></div>
      <div class="information">
        <p class="title">产品资料</p>
        <div class="table">
          <div
            class="ziliao"
            v-for="(item, index) in dataList"
            :key="index"
            @click="cpinformation(item)"
          >
            <div class="lxtupian">
              <img :src="item.img" alt="" class="mainpic" />
            </div>
            <p class="ziliaoname">{{ item.title }}</p>
          </div>
        </div>
        <!-- <div class=" table">
          <div class="ziliao"
               v-for="(item1,index1) in Lists"
               :key="index1"
               @click="cpinformation(item1)">
            <div class="lxtupian">
              <img :src="item1.img"
                   alt=""
                   class="mainpic">
            </div>
            <p class="ziliaoname">{{item1.title}}</p>
          </div>
        </div> -->
      </div>
    </div>
    <md-landscape v-model="showPic">
      <div class="showsctp"></div>
    </md-landscape>
  </div>
</template>

<script>
import { selecschb, selectcpzl, getInsureUrl } from "@/api/agent/agent/index";
import { getStorage } from "@/lib/util";
import { Toast } from "mand-mobile";
export default {
  components: {},
  data() {
    return {
      prodcode: "",
      ossurl: "",
      iconhttp: "",
      dataList: [],
      list: [
        {
          img: require("@/assets/nh/nhwx-101.png"),
          title: "费率表",
          code: "FLB",
        },
        {
          img: require("@/assets/nh/nhwx-102.png"),
          title: "健康告知",
          code: "JKGZ",
        },
        {
          img: require("@/assets/nh/nhwx-103.png"),
          title: "投保须知",
          code: "TBXZ",
        },
        {
          img: require("@/assets/nh/nhwx-104.png"),
          title: "职业类别",
          code: "ZYFL",
        },
        {
          img: require("@/assets/nh/nhwx-105.png"),
          title: "常见问题",
          code: "CJWT",
        },
        {
          img: require("@/assets/nh/nhwx-106.png"),
          title: "附加条款",
          code: "FJFW",
        },
        {
          img: require("@/assets/nh/nhwx-107.png"),
          title: "保全理赔",
          code: "BQLP",
        },
        {
          img: require("@/assets/nh/nhwx-108.png"),
          title: "产品条款",
          code: "CPTK",
        },
        {
          img: require("@/assets/nh/nhwx-109.png"),
          title: "产品介绍",
          code: "CPJS",
        },
      ],
      // Lists: [
      // ],
      sctp: "",
      showPic: false,
      ziliao: [],
      prodcp: "",
      showfile: [],
    };
  },
  created() {
    this.ossurl = getStorage("ossurl", "");
    this.u_s = getStorage("u_s", "");
    this.prodcode = this.$route.query.prodcode;
    this.information();
  },
  methods: {
    jump() {
      let tburl;
      if (this.u_s.rytype == "W" && this.u_s.status == "6") {
        this.$router.push(`/diaoauth`);
      } else {
        if (this.prodcp.tbflag == "1") {
          this.prodcp.comtburl
            ? (window.location.href = this.prodcp.comtburl)
            : this.$router.push(`/productShowNhsx?prodcode=${this.prodcode}`);
          // } else if (this.prodcp.baseid === 'HDJJ') {
          //   const data = {
          //     url: this.prodcp.comtburl,
          //     baseid: this.prodcp.baseid
          //   }
          //   getInsureUrl(data).then(response => {
          //     window.location.href = response.data.data
          //   })
        } else {
          if (this.prodcp.comtburl) {
            this.$store
              .dispatch("SALES_PERMISSION", {
                url: this.prodcp.comtburl,
                baseid: this.prodcp.baseid,
              })
              .then((response) => {
                if (response.urltype == "W" && this.prodcp.baseid == "YSYF") {
                  tburl = `${this.prodcp.comtburl.split("?")[0]}?userTrack=${
                    response.userTrack
                  }`;
                } else if (
                  response.urltype == "W" &&
                  this.prodcp.baseid == "ZAZX"
                ) {
                  tburl = `${
                    this.prodcp.comtburl.split("param=")[0]
                  }&bizContent=${response.bizContent}`;
                } else if (this.prodcp.baseid == "ZXJJ") {
                  if (this.prodcp.comtburl.includes("userTrack") > -1) {
                    tburl = this.prodcp.comtburl.replace(
                      "userTrack",
                      response.agent
                    );
                  } else {
                    tburl = `${this.prodcp.comtburl}&agent=${response.agent}`;
                  }
                } else if (response.urltype == "W") {
                  tburl = this.prodcp.comtburl.includes("?")
                    ? `${this.prodcp.comtburl}&comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`
                    : `${this.prodcp.comtburl}? comid=${response.comid}&prodcode=${response.prodcode}&empno=${response.empno}`;
                } else {
                  tburl = this.prodcp.comtburl;
                }
                // console.log(tburl)
                window.location.href = tburl;
                // this.user.rytype == 'M'
                //   ? (window.location.href = val.tburl)
                //   : this.behaviorRecord(val)
              });
            // window.location.href = this.prodcp.comtburl
          } else {
            this.$router.push(`/productShowNhwx?prodcode=${this.prodcode}`);
          }
        }
      }
    },
    information() {
      selectcpzl({ prodcode: this.prodcode, qdbk: "1" }).then((res) => {
        let arr = res.data.data.showfile;
        this.prodcp = res.data.data.showmsg;
        this.list.forEach((item, index) => {
          arr.forEach((item1, index1) => {
            if (item.code == item1.code) {
              this.dataList.push(item);
            }
          });
        });
      });
    },
    use() {
      if (this.u_s.rytype == "W" && this.u_s.status == "6") {
        this.$router.push(`/diaoauth`);
      } else {
        Toast.loading("生成中...");
        $(document).ready(function () {
          $(".showsctp").empty();
        });
        this.sctp = "";
        selecschb({ prodcode: this.prodcode }).then((res) => {
          this.sctp = res.data.data;
          if (this.sctp == "") {
            Toast.info("该产品暂无海报，无法生成");
          } else {
            let aaa = "<img src='" + this.ossurl + this.sctp + "' alt='' />";
            $(document).ready(function () {
              $(".showsctp").append(aaa);
            });
            this.showPic = true;
            Toast.hide();
          }
        });
      }
    },
    cpinformation(val) {
      this.$router.push(
        `/pdfinformation?prodcode=${this.prodcode}&code=${val.code}`
      );
    },
  },
};
</script>

 <style scoped>
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.showImg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2.5rem;
  border-radius: 0.2rem;
  position: relative;
  height: 3rem;
}
.showLeft {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0.5rem 0 0.5rem;
  height: 4.5rem;
}
.showTitle {
  width: 65%;
}
.cpname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.45rem;
  margin-top: 0.3rem;
  color: #383838;
  font-family: PingFang-SC-Medium;
}
.lookcp {
  margin-top: 0.3rem;
  font-size: 0.3rem;
  color: #989898;
}
.information {
  margin-top: 0.5rem;
  padding: 0 0.5rem 0 0.5rem;
}
.title {
  font-size: 0.45rem;
  font-family: PingFang-SC-Medium;
  color: #3d3d3d;
}
.mainpic {
  width: 0.6rem;
  height: 0.6rem;
}
.table {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
}
.ziliao {
  width: 25%;
  margin: 0.2rem 0;
}
.ziliaoname {
  text-align: center;
  color: #989898;
  font-family: PingFang-SC-Medium;
  font-size: 0.3rem;
}
.lxtupian {
  text-align: center;
}
.hbsc {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 0 0 0.2rem 0.2rem;
  background-color: rgba(0, 0, 0, 0.5);
  height: 0.6rem;
  display: flex;
  align-items: center;
}
.ztyangshi {
  font-size: 0.3rem;
  color: rgba(255, 255, 255);
  margin-left: 0.4rem;
}
.seemainpic {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.1rem;
}
</style>
